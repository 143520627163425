.slider {
	margin-top: 40px;
	color: #000;

	&-content {
		margin-top: 90px;

		@include media-breakpoint-down(sm) {
			margin-top: 50px;
		}
	}

	&-title {
		font-family: $font-accent;
		font-size: 2rem;
		font-weight: 700;

		@include media-breakpoint-down(sm) {
			font-size: 2rem;
		}
	}

	&-subtitle {
		margin-top: 10px;
		color: $base-text-color;
		font-size: 1.1rem;
	}

	&-btn {
		margin-top: 45px;

		@include media-breakpoint-down(lg) {
			margin-bottom: 30px;
		}
	}

	&-img {
		margin-top: 40px;

		@include media-breakpoint-down(xs) {
			img {
				width: 100%;
			}
		}
	}

	.slick-dots {
		position: absolute;
		top: 30px;
		padding: 0;
		margin: 0;

		@include media-breakpoint-down(sm) {
			top: 0;
		}

		li {
			display: inline-block;
			margin-right: 19px;
			list-style-type: none;

			&:last-child {
				margin-right: 0;
			}
		}

		button {
			width: 43px;
			height: 4px;
			border: 0;
			background-color: #cbd4dd;
			border-radius: 24px;
			font-size: 0;

			&:focus {
				outline: none;
			}
		}

		.slick-active {
			button {
				background-color: $accent-light-color;
			}
		}
	}
}
