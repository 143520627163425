// Colors
$body-bg: #f6f7fb;
$base-text-color: #333;
$accent-color: #387be0;
$accent-light-color: #859bbb;
$accent-color-hover: #3069bf;
$black-color: #000;
$sale-color: #ee6552;
$error-color: #f1492c;
$good-color: #8ec656;
$top-line-text-color: #515151;
$dissabled-color: #e0e7f2;
$dark-color: #566776;
$bg-banner: #373c43;
$bg-accent: #e5eef5;
$bg-block: #eaf2fc;

// Size
$base-font-size: 16px;

// Fonts
$font-default: "Exo 2", sans-serif;
$font-accent: "Exo 2", sans-serif;
$font-code: "SFMono-Regular", "Menlo", "Monaco", "Consolas", "Liberation Mono", "Courier New", monospace;

$grid-breakpoints: (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px);

// Animate variables
$use-all: true;
