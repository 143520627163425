.button {
	padding: 1em 1.8em;
	background-color: $black-color;
	border-radius: 10px;
	color: #fff;
	font-family: $font-accent;
	font-size: 0.9em;
	font-weight: 500;
	transition: all .25s ease;

	&:hover {
		color: #fff;
		cursor: pointer;
	}

	&:focus,
	&:active {
		outline: none;
	}

	&:active {
		position: relative;
		top: 1px;
	}

	&-blue {
		background-color: $accent-color;

		&:hover {
			background-color: $accent-color-hover;
		}
	}

	&-light {
		border: 1px solid #fff;
		background-color: transparent;
		opacity: .9;

		&:hover {
			background-color: #fff;
			color: #000;
		}
	}

	&-big {
		font-size: 1.1em;
	}
}
