.header {
	padding-top: 13px;

	@include media-breakpoint-down(md) {
		padding-bottom: 13px;
	}
}

.head-logo {
	margin-top: 5px;

	@include media-breakpoint-down(lg) {
		svg {
			width: 150px;
		}
	}
}

.head-links {
	display: flex;
	flex-grow: 1;
	align-items: center;
	justify-content: flex-start;
	margin-top: 28px;
}

.head-link {
	margin-right: 25px;
	line-height: 0;

	@include media-breakpoint-down(md) {
		margin-top: 30px;
		margin-right: 0;
	}

	a {
		margin-right: 10px;
		color: $top-line-text-color;
		font-size: 0.95rem;

		&::after {
			margin-left: 10px;
			content: "/";
		}

		&:last-child {
			margin-right: 0;

			&::after {
				content: "";
			}
		}

		&:hover {
			color: $base-text-color;
		}
	}

	&:last-child {
		margin-right: 0;
	}

	&__icon {
		position: relative;
		top: -1px;
		width: 15px;
		height: 15px;
		margin-right: 5px;
	}

	&__name {
		color: $top-line-text-color;
		font-size: 0.95rem;

		&:hover {
			color: $base-text-color;
		}
	}
}

.head-contact {
	display: flex;
	justify-content: flex-end;

	&__callback {
		margin-right: 20px;
	}

	&__messagers {
		display: flex;
		margin-top: 20px;

		a {
			margin-right: 10px;
			opacity: .4;
			transition: opacity .1s ease;

			&:hover {
				opacity: .6;
			}

			&:last-child {
				margin-right: 0;
			}
		}
	}
}

.head-phone {
	margin-top: 8px;
	margin-left: 35px;
	text-align: right;

	@include media-breakpoint-down(md) {
		margin-top: 30px;
		margin-right: 0;
		text-align: left;
	}

	&:first-child {
		margin-left: 0;
	}

	&__icon {
		margin-right: 5px;

		@include media-breakpoint-down(md) {
			display: none;
		}
	}

	&__number {
		color: $black-color;
		font-family: $font-accent;
		font-size: 1.2rem;
		font-weight: 500;
		white-space: nowrap;

		&:hover {
			color: $black-color;
		}
	}

	&__desc {
		display: block;
		color: #646464;
		font-size: 0.8rem;
	}
}

.mobile-menu-icon {
	position: relative;

	.icon {
		position: absolute;
		top: 20px;
		right: 0;
		width: 29px;
		height: 24px;
		background-image: url("../../img/icons/menu-open.svg");
		background-repeat: no-repeat;
	}

	.close {
		background-image: url("../../img/icons/menu-close.svg");
	}
}

.mobile-menu {
	position: absolute;
	z-index: 100;
	top: 90px;
	right: 0;
	bottom: 0;
	left: 0;
	display: none;
	width: 100%;
	height: 100vh;
	background-color: rgba(0, 0, 0, 0.5);

	.content {
		padding: 0 15px 30px;
		background-color: $body-bg;

		.menu {
			padding-top: 20px;

			.list {
				padding-left: 0;
				margin: 5px 0 0;

				.item {
					position: relative;
					margin-bottom: 13px;
					font-family: $font-default;
					font-size: 18px;
					font-weight: 400;
					list-style-type: none;

					.link {
						padding: 20px 0;
						color: $base-text-color;
					}
				}

				.arrow {
					position: relative;
					padding-right: 0;

					&::after {
						position: absolute;
						top: 8.8px;
						right: 0;
						display: inline-block;
						padding: 2px;
						border: solid #35404e;
						border-width: 0 2px 2px 0;
						border-radius: 0.5px;
						content: "";
						transform: rotate(45deg);
					}
				}

				.submenu-list {
					position: relative;
					z-index: 9;
					display: none;
					padding: 15px;
					margin-top: 0;
					background-color: transparent;
					border-radius: 9px;
					box-shadow: none;
					opacity: 1;
					transition: all .2s ease;
					visibility: visible;

					li {
						white-space: nowrap;
					}
				}

				.submenu-item {
					padding: 5px 12px;
					margin-bottom: 3px;
					list-style-type: none;

					&:last-child {
						margin-bottom: 0;
					}

					&:hover {
						background-color: lighten($accent-color, 43%);
					}
				}

				.submenu-link {
					display: flex;
					align-items: center;
					margin-right: 50px;
					color: #000;

					&::before {
						display: block;
						width: 35px;
						height: 35px;
						margin-top: 8px;
						margin-right: 15px;
						background-size: cover;
						content: "";
						float: left;
						transition: all .25s ease;
					}

					&-admin {
						&::before {
							background-image: url("../../img/pack/customer-support.svg");
						}
					}

					&-backup {
						&::before {
							background-image: url("../../img/pack/servers-sync.svg");
						}
					}

					&-company {
						&::before {
							background-image: url("../../img/pack/company.svg");
						}
					}
				}
			}
		}

		.account {
			margin-top: 36px;

			.link {
				position: relative;
				display: inline-block;
				padding: 8px 22px;
				border: 1px solid #859bbb;
				border-radius: 10px;
				color: #373c43;
				font-family: $font-accent;
				font-size: 0.9rem;
				font-weight: 600;
			}
		}

		.links {
			display: flex;
			flex-grow: 1;
			align-items: center;
			justify-content: flex-start;
			margin-top: 0;

			.link {
				margin-top: 30px;
				margin-right: 0;

				a {
					margin-right: 10px;
					color: $top-line-text-color;
					font-size: 0.95rem;

					&::after {
						margin-left: 10px;
						content: "/";
					}

					&:last-child {
						margin-right: 0;

						&::after {
							content: "";
						}
					}

					&:hover {
						color: $base-text-color;
					}
				}

				&:last-child {
					margin-right: 0;
				}

				&__icon {
					position: relative;
					top: -1px;
					width: 15px;
					height: 15px;
					margin-right: 5px;
				}

				&__name {
					color: $top-line-text-color;
					font-size: 0.95rem;

					&:hover {
						color: $base-text-color;
					}
				}
			}
		}

		.phone {
			margin-top: 30px;
			margin-right: 0;
			text-align: left;

			&:first-child {
				margin-left: 0;
			}

			.icon {
				display: none;
			}

			.number {
				color: $black-color;
				font-family: $font-accent;
				font-size: 1.2rem;
				font-weight: 500;

				&:hover {
					color: $black-color;
				}
			}

			.desc {
				display: block;
				color: #646464;
				font-size: 0.85rem;
			}
		}
	}
}

.main-line {
	&-account {
		display: flex;
		justify-content: flex-end;
		margin: 20px 0;

		@include media-breakpoint-down(sm) {
			display: none;
		}

		&__name {
			position: relative;
			display: inline-block;
			padding: 8px 22px;
			border: 1px solid $accent-light-color;
			border-radius: 10px;
			color: #373c43;
			font-family: $font-accent;
			font-size: 0.9rem;
			font-weight: 600;
		}

		&:hover {
			.main-line-account__name {
				border: 1px solid darken($accent-light-color, 10%);
				color: #000;
			}
		}
	}
}

.head-menu {
	@include media-breakpoint-down(md) {
		display: none;
	}
}

.main-menu {
	margin-top: 25px;
	margin-bottom: 20px;

	.menu-list {
		padding: 0;
		margin: 5px 0 0;
	}

	.menu-item {
		position: relative;
		display: inline-block;
		margin-right: 45px;
		cursor: pointer;
		font-family: $font-default;
		font-weight: 500;
		list-style-type: none;

		&:last-child {
			margin-right: 0;
		}
	}

	.menu-link {
		padding: 20px 0;
		color: $base-text-color;

		&:hover {
			color: $black-color;
		}
	}

	.menu-item-arrow {
		position: relative;
		padding-right: 16px;

		@include media-breakpoint-down(md) {
			padding-right: 0;
		}

		&::after {
			position: absolute;
			top: 8.8px;
			right: -5px;
			display: inline-block;
			padding: 2px;
			border: solid #35404e;
			border-width: 0 2px 2px 0;
			border-radius: 0.5px;
			content: "";
			transform: rotate(45deg);
		}

		&:hover {
			.submenu-list {
				margin-top: 0;
				opacity: 1;
				visibility: visible;
			}
		}
	}

	.menu-link-active {
		color: $black-color;
	}

	.submenu-list {
		position: absolute;
		z-index: 9;
		padding: 15px;
		margin-top: 10px;
		background-color: #fff;
		border-radius: 9px;
		box-shadow: 14px 4px 22px rgba(0, 0, 0, 0.09);
		opacity: 0;
		transition: all .2s ease;
		visibility: hidden;

		@include media-breakpoint-down(md) {
			position: relative;
			display: none;
			margin-top: 0;
			background-color: transparent;
			box-shadow: none;
			opacity: 1;
			visibility: visible;
		}

		li {
			white-space: nowrap;
		}
	}

	.submenu-item {
		padding: 5px 12px;
		margin-bottom: 3px;
		list-style-type: none;

		&:last-child {
			margin-bottom: 0;
		}

		&:hover {
			background-color: lighten($accent-color, 43%);
		}
	}

	.submenu-link {
		display: inline-block;
		margin-right: 50px;
		color: #000;

		@include media-breakpoint-down(md) {
			display: flex;
			align-items: center;
		}

		&::before {
			display: block;
			width: 35px;
			height: 35px;
			margin-top: 8px;
			margin-right: 15px;
			background-size: cover;
			content: "";
			float: left;
			transition: all .25s ease;
		}

		&-admin {
			&::before {
				background-image: url("../../img/pack/customer-support.svg");
			}
		}

		&-backup {
			&::before {
				background-image: url("../../img/pack/servers-sync.svg");
			}
		}

		&-company {
			&::before {
				background-image: url("../../img/pack/company.svg");
			}
		}
	}

	.submenu-descr {
		display: block;
		margin-left: 50px;
		color: $base-text-color;
		font-size: 0.8rem;
		font-weight: 400;

		@include media-breakpoint-down(md) {
			display: none;
		}
	}
}
