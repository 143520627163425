html,
body {
	min-width: 320px;
	background-color: $body-bg;
	background-image: url("../img/bg-elem/elem-header.svg");
	background-position: top center;
	background-repeat: no-repeat;
	color: $base-text-color;
	font-family: $font-default;
	font-size: $base-font-size;
	line-height: 1.65;

	@include media-breakpoint-down(md) {
		background-image: none;
	}
}

a {
	color: $accent-color;

	&:hover {
		text-decoration: none;
	}
}

h1,
h2,
h3,
h4 {
	font-family: $font-accent;
	font-weight: 700;
}

.slick-slide {
	outline: none;
}

section {
	margin-top: 120px;

	@include media-breakpoint-down(sm) {
		margin-top: 45px;
	}
}

.section-accent-bg {
	padding-top: 45px;
	padding-bottom: 60px;
	background-color: $bg-accent;
}

.section-last {
	margin-top: 0;
}

.block-style {
	background-image: url("../img/style-elem-block.svg");
	background-position: 100% 100%;
	background-repeat: no-repeat;
}

.block-style--large {
	background-size: 338px 182px;
}
