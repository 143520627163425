// Titles
.title-sect {
	position: relative;
	margin-bottom: 45px;
	color: $black-color;
	font-family: $font-accent;
	font-size: 30px;
	font-weight: 500;
	text-transform: uppercase;

	@include media-breakpoint-down(sm) {
		font-size: 26px;
		line-height: 1.4;
		text-align: center;
	}

	&--no-ui {
		&::before,
		&::after {
			display: none;
		}
	}

	&::before {
		position: absolute;
		bottom: -16px;
		left: 0;
		width: 45px;
		height: 8px;
		background-color: #bfcbd7;
		border-radius: 8px;
		content: "";

		@include media-breakpoint-down(sm) {
			right: 0;
			left: 0;
			width: 150px;
			height: 2px;
			margin: 0 auto;
		}
	}
}

.title-center {
	margin-bottom: 45px;
	color: #000;
	font-family: $font-accent;
	font-size: 30px;
	font-weight: 500;
	line-height: 1.3;
	text-align: center;

	@include media-breakpoint-down(sm) {
		font-size: 22px;
		line-height: 1.5;
	};

	&::before {
		right: 0;
		left: 0;
		margin: 0 auto;
	}
}

// Checkbox
.check {
	position: relative;
	display: inline-block;
	align-items: center;
	padding-left: 28px;
	cursor: pointer;
	line-height: 1.4;

	&__input {
		position: absolute;
		appearance: none;

		&:checked + .check__box {
			border-color: $accent-light-color;
			background-image: url("../img/ui/checkbox/check-base.svg");
		}

		&:hover + .check__box {
			border-color: darken($accent-light-color, 10%);
		}

		&:hover:checked + .check__box {
			border-color: darken($accent-light-color, 10%);
			background-image: url("../img/ui/checkbox/check-base.svg");
		}

		&:disabled + .check__box {
			border-color: $dissabled-color;
		}

		&:disabled:checked + .check__box {
			border-color: $dissabled-color;
		}
	}

	&__box {
		position: absolute;
		width: 19px;
		height: 19px;
		border: 2px solid $accent-light-color;
		margin-left: -28px;
		background-color: #fff;
		background-position: center 1px;
		background-repeat: no-repeat;
		border-radius: 5px;
	}
}

// Links
.link {
	color: $accent-color;
	cursor: pointer;
	transition: color .25s ease;

	&:hover {
		color: $accent-color-hover;
	}
}

.link-underline {
	border-bottom: 1px dashed $accent-color;

	&:hover {
		border-color: $accent-color-hover;
	}
}

.link-big {
	font-family: $font-accent;
	font-weight: 600;
}

// Forms
.form {
	&__input {
		padding: 18px;
		border: 1px solid $accent-light-color;
		border-radius: 10px;
		line-height: 1rem;
		outline: none;
	}

	.error {
		border: 1px solid $error-color;
		color: $error-color;
	}

	&__input-file {
		position: absolute;
		overflow: hidden;
		width: 0.1px;
		height: 0.1px;
		padding-top: 0;
		padding-left: 0;
		border: 0;
		appearance: none;
		opacity: 0;
	}

	&__label-file {
		margin-top: 10px;
	}

	&__button {
		border: 0;
	}

	&__check {
		text-align: left;
	}
}

// Text
.text-justify {
	text-align: justify;
}

.text-left {
	text-align: left;
}

// Select
.select {
	select {
		display: block;
		padding-right: 20px;
		border: 0;
		appearance: none;
		background-color: transparent;
		background-image: url("../img/icons/select-icon.svg");
		background-position: right 7px;
		background-repeat: no-repeat;

		&::-ms-expand {
			display: none;
		}
	}
}
