.backup-rate {
	display: flex;
	align-items: center;
	justify-content: space-between;

	@include media-breakpoint-down(lg) {
		display: block;
	}

	&-wrap {
		width: 730px;
		padding: 25px 30px 35px;
		background-color: #fff;
		border-radius: 8px;
		box-shadow: 4px 4px 22px rgba(56, 123, 224, 0.15);

		@include media-breakpoint-down(lg) {
			margin: 0 auto 30px;
		}
		@include media-breakpoint-down(sm) {
			width: 100%;
		}
	}

	&-title {
		font-family: $font-accent;
		font-size: 22px;
		font-weight: 500;
	}

	&-config {
		margin-top: 45px;

		&-price {
			margin-left: 50px;
			font-family: $font-accent;
			font-size: 19px;
			font-weight: 600;

			@include media-breakpoint-down(sm) {
				order: 1;
			}
		}

		&-bottom {
			display: flex;
			align-items: center;
			margin-top: 70px;
		}

		&__param {
			display: none;
		}
	}

	&-info {
		display: flex;
		width: 400px;
		padding: 35px 30px;
		background-color: #e8ecf2;
		background-image: url("../../img/bg-elem/bg-info-backup.svg");
		background-repeat: no-repeat;
		border-radius: 0 10px 10px 0;
		justify-items: flex-start;

		@include media-breakpoint-down(lg) {
			margin: 0 auto;
			border-radius: 10px;
		}
		@include media-breakpoint-down(sm) {
			width: 100%;
		}
		@include  media-breakpoint-down(xs) {
			display: block;
			text-align: center;
		}

		&-text {
			margin-left: 20px;
			color: #555b6a;
		}
	}
}

.features {
	&-item {
		position: relative;
		padding: 40px 33px;
		margin-bottom: 40px;
		background-color: #fff;
		border-radius: 8px;
	}

	&-icon {
		position: absolute;
		top: -20px;
		right: 20px;
		display: flex;
		width: 64px;
		height: 64px;
		align-items: center;
		justify-content: center;
		background-color: #fff;
		border-radius: 14px;
	}

	&-title {
		margin-bottom: 5px;
		color: #555b6a;
		font-size: 22px;
	}

	&-text {
		color: #000;
		font-size: 22px;
		font-weight: 500;
	}
}

.statistics {
	&-item {
		margin-bottom: 30px;
	}

	&-num {
		position: relative;
		font-size: 33px;
		font-weight: 700;

		&::before {
			position: absolute;
			top: 25px;
			right: 0;
			width: 70%;
			height: 4px;
			background-color: #f74e4e;
			border-radius: 5px;
			content: "";
		}
	}

	&-text {
		color: #555b6a;
	}
}

.location {
	background-image: url("../../img/location-bg.svg");
	background-position: center center;
	background-size: cover;
	border-radius: 8px;
	color: #fff;

	.title-sect {
		padding-top: 30px;
		color: #fff;

		&::before {
			background-color: #506168;
		}
	}

	&-item {
		margin-bottom: 30px;
		text-align: center;
	}

	&-icon {
		display: flex;
		height: 100px;
		align-items: center;
		justify-content: center;
		margin-bottom: 18px;
	}

	&-title {
		opacity: 0.6;
	}

	&-text {
		font-size: 22px;
	}
}

.plus-backup {
	&-item {
		margin-bottom: 30px;
		text-align: center;

		@include media-breakpoint-down(md) {
			margin-bottom: 60px;
		}
	}

	&-title {
		margin-top: 29px;
		margin-bottom: 14px;
		font-size: 24px;
		font-weight: 500;
	}

	&-text {
		color: #555b6a;
	}

	&-icon {
		display: flex;
		width: 98px;
		height: 98px;
		align-items: center;
		justify-content: center;
		margin: 0 auto;
		background-color: #fff;
		border-radius: 10px;
		box-shadow: 4px 4px 22px rgba(56, 123, 224, 0.15);
	}
}
