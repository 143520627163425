.server-conf-sect {
	background-image: url("../../img/bg-elem/elem-left.svg");
	background-position: top left;
	background-repeat: no-repeat;
	background-size: 200px;

	@include media-breakpoint-down(md) {
		display: none;
	}
}

.bg-elem {
	background-image: url("../../img/bg-elem/elem-left.svg");
	background-position: center left;
	background-repeat: no-repeat;
}

.server-conf {
	position: relative;
	padding: 27px 41px;
	background-color: #fff;
	border-radius: 8px;
	box-shadow: 4px 4px 22px rgba(56, 123, 224, 0.15);

	@include media-breakpoint-down(xs) {
		padding: 15px 30px;
	}

	&__wrap {
		display: flex;

		@include media-breakpoint-down(sm) {
			flex-direction: column;
		}
	}

	&__loader {
		position: absolute;
		z-index: 100;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		display: flex;
		width: 100%;
		height: 100%;
		align-items: center;
		justify-content: center;
		background-color: #fff;
	}

	&__left {
		position: relative;
		flex: 1.5;
		margin-right: 60px;

		@include media-breakpoint-down(sm) {
			margin-right: 0;
		}

		&::after {
			position: absolute;
			top: 0;
			right: -30px;
			bottom: 0;
			width: 1px;
			height: 100%;
			background-color: $black-color;
			content: "";
			opacity: .1;

			@include media-breakpoint-down(sm) {
				display: none;
			}
		}
	}

	&__right {
		flex: 1;
	}

	&__item {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		margin-bottom: 60px;

		@include media-breakpoint-down(xs) {
			display: block;
			margin-bottom: 70px;
		}

		&:last-child {
			@include media-breakpoint-down(xs) {
				margin-bottom: 40px;
			}
		}
	}

	&__title {
		margin-bottom: 25px;
		color: $black-color;
		font-family: $font-accent;
		font-size: 1.35rem;

		@include media-breakpoint-down(sm) {
			margin-top: 30px;
			text-align: center;
		}
	}

	&__slider {
		flex: 1 0 auto;
	}

	&__cp {
		@include media-breakpoint-down(sm) {
			margin-top: 20px;
			text-align: center;
		}
	}

	&__param {
		min-width: 80px;
		color: $accent-light-color;
		font-size: 0.9rem;

		@include media-breakpoint-down(xs) {
			margin-bottom: 30px;
		}

		&:first-child {
			position: relative;
			padding-left: 20px;
			margin-right: 20px;
			opacity: .7;

			@include media-breakpoint-down(xs) {
				margin-right: 0;
			}

			&::before {
				position: absolute;
				top: 1px;
				width: 20px;
				height: 20px;
				margin-left: -30px;
				content: "";
			}
		}

		&--cpu {
			&::before {
				background-image: url("../../img/icons/cpu.svg");
			}
		}

		&--ram {
			&::before {
				background-image: url("../../img/icons/server.svg");
			}
		}

		&--disk {
			&::before {
				background-image: url("../../img/icons/hard-drive.svg");
			}
		}

		&:last-child {
			display: none;
			margin-left: 20px;
			text-align: left;
		}
	}

	&__bottom {
		display: flex;

		&-left {
			display: flex;
			flex: 1.5;
			align-items: flex-end;

			@include media-breakpoint-down(md) {
				flex-direction: column;
			}
		}

		&-right {
			flex: 1;
			text-align: left;

			@include media-breakpoint-down(md) {
				display: none;
			}
		}
	}

	&__btn {
		flex: 1;
		margin-top: 35px;
		margin-bottom: 20px;

		@include media-breakpoint-down(md) {
			order: 2;
		}
		@include media-breakpoint-down(sm) {
			width: 100%;
			text-align: center;

			.button {
				display: block;
			}
		}
	}

	&__help {
		margin-left: 35px;

		@include media-breakpoint-down(md) {
			margin-left: 0;
		}

		span {
			position: relative;
			padding-top: 10px;
			border-color: $accent-light-color;
			margin-left: 54px;
			color: $accent-light-color;

			&::before {
				position: absolute;
				top: 0;
				left: -64px;
				width: 44px;
				height: 44px;
				background-image: url("../../img/icons/help-config.svg");
				content: "";
			}

			&:hover {
				color: darken($accent-light-color, 10%);
			}
		}
	}

	&__price {
		flex: 1;
		margin-right: 30px;
		text-align: right;

		@include media-breakpoint-down(md) {
			order: 1;
		}
		@include media-breakpoint-down(sm) {
			width: 100%;
			margin-right: 0;
			text-align: center;
		}

		&-select {
			display: flex;
			justify-content: flex-end;

			@include media-breakpoint-down(sm) {
				justify-content: center !important;
			}
			@include media-breakpoint-down(md) {
				justify-content: flex-start;
				margin-top: 20px;
			}

			> span {
				opacity: .8;
			}

			> div {
				margin-left: 15px;
			}
		}

		&-num {
			margin-right: 5px;
		}

		&-total {
			margin-top: 11px;
			color: $black-color;
			font-family: $font-accent;
			font-size: 1.3rem;
			font-weight: 500;
			line-height: 1.4;

			@include media-breakpoint-down(md) {
				text-align: left;
			}
			@include media-breakpoint-down(sm) {
				text-align: center !important;
			}
		}
	}
}

.select-price {
	&__link {
		padding: 2px 14px 5px;
		border: 1px solid $accent-light-color;
		cursor: pointer;

		&:first-child {
			padding-right: 10px;
			border-radius: 4px 0 0 4px;
		}

		&:last-child {
			border-radius: 0 4px 4px 0;
		}

		&--active {
			background: $accent-light-color;
			color: #fff;

			&:hover {
				color: #fff;
			}
		}
	}
}

.server-conf-tags {
	&__list {
		padding: 0;
		margin: 0;

		@include media-breakpoint-down(sm) {
			text-align: center;
		}
	}

	&__item {
		display: inline-block;
		margin-right: 10px;
		margin-bottom: 10px;

		&:last-child {
			margin-right: 0;
		}
	}

	&__btn {
		display: inline-block;
		padding: 9px 20px;
		border: 1px solid $accent-light-color;
		border-radius: 9px;
		color: $black-color;
		cursor: pointer;
		font-size: 0.9rem;
		line-height: 1.4;

		@include media-breakpoint-down(md) {
			padding: 5px 16px;
		}

		&:hover {
			border-color: $accent-light-color;
			color: $accent-light-color;
		}

		&--active {
			background-color: $accent-light-color;
			color: #fff;

			&:hover {
				color: #fff;
			}
		}
	}
}

.assemblies {
	&-slick {
		display: flex;
		margin: 0 -10px;
	}

	&-item {
		width: 170px;
		padding: 0 10px;
	}
}

.assemblies-nav {
	display: flex;
	justify-content: flex-end;
	margin-top: -45px;
	margin-bottom: 30px;

	@include media-breakpoint-down(xs) {
		justify-content: center;
		margin-top: -10px;
	}

	button {
		display: flex;
		align-items: flex-start;
		border: 0;
		background: transparent;

		&:focus {
			outline: none;
		}

		img {
			transition: all .2s ease;

			&:hover {
				opacity: .4;
			}
		}
	}

	.next {
		margin-left: 5px;
		transform: rotateZ(180deg);
		transform-origin: center;
	}
}

.assembly {
	position: relative;
	overflow: hidden;
	padding: 27px 15px 10px;
	margin-bottom: 20px;
	background-color: #fff;
	border-radius: 8px;
	box-shadow: 4px 4px 10px rgba(56, 123, 224, 0.15);

	&__link {
		position: relative;
		color: $black-color;
		outline: none;
	}

	&__name {
		margin-bottom: 20px;
		color: $black-color;
		font-family: $font-accent;
		font-size: 1.2rem;
		font-weight: 500;
		text-align: center;
	}

	&__price {
		margin: 20px 0;
		font-family: $font-accent;
		font-size: 1.2rem;
		font-weight: 500;
		line-height: 1;
		text-align: center;
		white-space: nowrap;
	}

	&__btn {
		margin-bottom: 1.2rem;
	}

	&__button {
		display: block;
		padding: 10px 0;
		font-size: 1rem;
		text-align: center;
	}
}

.assembly-table {
	&__item {
		display: flex;
		justify-content: center;
		margin-bottom: 10px;
		font-size: 1rem;

		&:last-child {
			margin-bottom: 0;
		}
	}

	&__name {
		padding-left: 5px;
	}
}

.benefits-item {
	padding: 20px 0;
	text-align: center;
	transition: all .25s ease;

	@include media-breakpoint-down(md) {
		margin-bottom: 45px;
	}

	&:hover {
		position: relative;
		border-radius: 8px;
		box-shadow: 0 15px 25px #c1d0db;
		transform: translateY(-10px);
	}

	&__number {
		margin-top: 15px;
		margin-bottom: 1px;
		color: $black-color;
		font-family: $font-accent;
		font-size: 1.5rem;
		font-weight: 600;
	}
}

.article-sect {
	background-image: url("../../img/bg-elem/elem-white-left.svg");
	background-position: top left;
	background-repeat: no-repeat;
}

.why-we {
	background-image: url("../../img/bg-elem/elem-right.svg");
	background-position: top right;
	background-repeat: no-repeat;

	&-item {
		position: relative;
		height: 266px;
		padding: 43px 33px;
		margin-bottom: 40px;
		background-color: $bg-block;
		border-radius: 10px;

		@include media-breakpoint-down(lg) {
			height: 340px;
		}
		@include media-breakpoint-down(sm) {
			height: auto !important;
		}

		&:hover {
			.why-we-item__icon {
				top: -30px;
				box-shadow: 0 4px 7px rgba(0, 0, 0, 0.04);
			}
		}

		&__icon {
			position: absolute;
			top: -25px;
			right: 14px;
			display: flex;
			width: 64px;
			height: 64px;
			align-items: center;
			justify-content: center;
			background-color: #fff;
			border-radius: 14px;
			transition: all .25s ease;
		}

		&__title {
			margin-bottom: 11px;
			font-size: 1.1rem;
			font-weight: 500;

			@include media-breakpoint-down(lg) {
				font-size: 1.1rem;
			}
		}

		&__text {
			font-size: 1rem;
		}
	}
}

.promo-block {
	position: relative;
	padding: 45px 60px 68px;
	background: linear-gradient(180deg, #273b44 0%, #132a34 100%);
	border-radius: 8px;
	box-shadow: 4px 4px 22px rgba(56, 123, 224, 0.15);
	color: #fff;

	@include media-breakpoint-down(md) {
		text-align: center;
	}
	@include media-breakpoint-down(xs) {
		padding: 20px 30px;
	}

	//&::before {
	//	background-image: url("../../img/visual-elem-logo.svg");
	//	background-position: 70% center;
	//	background-repeat: no-repeat;
	//	bottom: 0;
	//	content: "";
	//	left: 0;
	//	position: absolute;
	//	right: 0;
	//	top: 0;
	//}

	&__title {
		max-width: 650px;
		margin-bottom: 10px;
		font-family: $font-accent;
		font-size: 1.8rem;
		font-weight: 600;
		line-height: 1.5;

		@include media-breakpoint-down(xs) {
			margin-top: 10px;
			margin-bottom: 25px;
			font-size: 1.6rem;
			line-height: 1.4;
		}
	}

	&__desc {
		max-width: 680px;
		margin-bottom: 40px;
		font-size: 1.15rem;
		opacity: .8;
	}

	&__btn {
		position: relative;
		margin-bottom: 20px;

		.button {
			@include media-breakpoint-down(xs) {
				display: block;
				font-size: 1rem;
			}
		}
	}

	&__img {
		position: absolute;
		right: 64px;
		bottom: 0;
	}

	&__form {
		position: relative;

		form {
			display: flex;
			align-items: flex-start;

			@include media-breakpoint-down(md) {
				display: block;
			}

			label {
				display: block;
				width: 315px;
				margin-right: 15px;

				@include media-breakpoint-down(sm) {
					width: 100% !important;
				}
				@include media-breakpoint-down(md) {
					width: 315px;
					margin: 0 auto 20px;
				}
			}

			input {
				width: 100%;
				padding: 21px 18px;
				margin-bottom: 0;
			}

			button {
				@include media-breakpoint-down(md) {
					width: 315px;
				}
				@include media-breakpoint-down(sm) {
					width: 100% !important;
					margin-bottom: 30px;
					font-size: 1rem;
				}
			}
		}

		.input__error {
			display: block;
			margin-top: 3px;
			color: $error-color;
		}

		&-success {
			position: relative;
			padding: 13px 0 13px 75px;
			color: $good-color;
			font-size: 1.1rem;

			@include media-breakpoint-down(xs) {
				padding: 75px 0 0;
				margin-bottom: 30px;
			}

			&::before {
				position: absolute;
				top: 0;
				left: 0;
				width: 56px;
				height: 56px;
				background-image: url("../../img/icons/success.svg");
				content: "";

				@include media-breakpoint-down(xs) {
					right: 0;
					margin: 0 auto;
				}
			}
		}
	}
}

.faq {
	&-wrap {
		padding: 20px 40px;
		background-color: #fff;
		border-radius: 8px;
		box-shadow: 4px 4px 22px rgba(56, 123, 224, 0.15);
	}

	&__item {
		border-top: 1px solid rgba(133, 155, 187, 0.5);
		cursor: pointer;

		&:first-child {
			border-top: 0;
		}

		&.faq-open {
			.faq__question::before {
				background-image: url("../../img/icons/faq-minus.svg");
			}

			.faq__answer {
				display: block;
			}
		}

		&.faq-close {
			.faq__question::before {
				background-image: url("../../img/icons/faq-pluse.svg");
			}

			.faq__answer {
				display: none;
			}
		}
	}

	&__question {
		position: relative;
		padding: 20px 0 24px 30px;
		font-family: $font-accent;
		font-size: 1.4rem;
		font-weight: 500;

		&::before {
			position: absolute;
			top: 27px;
			left: 0;
			width: 19px;
			height: 19px;
			content: "";
		}
	}

	&__answer {
		padding-left: 30px;
		margin-bottom: 30px;
		text-align: justify;

		p > a {
			color: #333;
		}
	}
}

.os-list {
	margin-top: 50px;

	&-item {
		text-align: center;
		transition: all .2s ease-in-out;

		@include media-breakpoint-down(md) {
			margin-bottom: 30px;
		}

		&:hover {
			transform: scale(1.1);
		}
	}

	&-name {
		margin-top: 10px;
		font-size: 1.15rem;
	}
}
