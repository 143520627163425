.logos {
	&__item {
		position: relative;
		width: 25%;
		height: 100px;
		margin: 0 auto 45px;
		text-align: center;

		@include media-breakpoint-down(md) {
			width: 50%;
			margin-bottom: 40px;
		}
		@include media-breakpoint-down(xs) {
			width: 100% !important;
		}

		&:hover {
			.logos__img:last-child {
				top: 0;
				opacity: 1;
			}
		}
	}

	&__img {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		max-width: 170px;
		margin: auto;
		transition: all .25s ease;

		&:first-child {
			filter: grayscale(100%);
			opacity: .7;

			&:hover {
				filter: grayscale(0%);
			}
		}

		&:nth-child(2) {
			top: 25px;
			opacity: 0;
			transition: .5s;
		}
	}

	&-static {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
	}
}

.pay-methods {
	&__title {
		margin-bottom: 23px;
		font-family: $font-accent;
		font-size: 1.4rem;
		font-weight: 600;

		@include media-breakpoint-down(sm) {
			text-align: center;
		}
	}

	&__individual {
		margin-bottom: 45px;

		&-item {
			margin-bottom: 18px;

			&:last-child {
				margin-bottom: 0;
			}
		}

		&-title {
			margin-bottom: 20px;
			font-family: $font-default;
			font-size: 1.15rem;
		}

		&-wrap {
			@include media-breakpoint-down(md) {
				margin-bottom: 30px;
			}
		}
	}

	&__company {
		display: flex;
		align-items: flex-start;

		@include media-breakpoint-down(sm) {
			display: block;
		}

		&__item {
			width: 25%;

			@include media-breakpoint-down(sm) {
				width: 100%;
			}
		}
	}
}

.pay-methods-individual-item {
	display: flex;
	align-items: center;

	&__logo {
		margin-right: 25px;
		transition: all .2s ease-in-out;
	}

	&__logo:hover {
		transform: scale(1.2);
	}

	&__name {
		font-size: 1.05rem;
	}
}

.pay-methods-company {
	@include media-breakpoint-down(sm) {
		margin-bottom: 30px;
	}

	&__icon {
		position: relative;
		width: 41px;
		height: 41px;
		border: 4px solid #d6dfea;
		margin: 0 auto;
		border-radius: 50%;

		&::after {
			position: absolute;
			width: 100%;
			height: 100%;
			background-position: center 7px;
			background-repeat: no-repeat;
			content: "";
		}

		&::before {
			position: absolute;
			top: 14px;
			left: 37px;
			width: 240px;
			height: 1px;
			border: 2px dashed #d6dfea;
			content: "";

			@include media-breakpoint-down(lg) {
				width: 200px;
			}
			@include media-breakpoint-down(md) {
				width: 130px !important;
			}
			@include media-breakpoint-down(sm) {
				border: 0;
			}
		}
	}

	&:last-child {
		.pay-methods-company__icon::before {
			display: none;
			width: 0;
		}
	}

	&:nth-child(1) {
		.pay-methods-company__icon::after {
			background-image: url("../../img/payments/file-text.svg");
		}
	}

	&:nth-child(2) {
		.pay-methods-company__icon::after {
			background-image: url("../../img/payments/layout.svg");
		}
	}

	&:nth-child(3) {
		.pay-methods-company__icon::after {
			background-image: url("../../img/payments/check-square.svg");
		}
	}

	&:nth-child(4) {
		.pay-methods-company__icon::after {
			background-image: url("../../img/payments/mail.svg");
		}
	}

	&__name {
		padding: 0 10px;
		margin-top: 10px;
		color: #494f58;
		font-size: 0.9rem;
		line-height: 1.4;
		text-align: center;
	}
}

.about__policy {
	.container {
		text-align: justify;
	}
}
