.error-page {
	.error-wrap {
		margin-top: 20%;
		text-align: center;

		.error-text {
			margin-top: 30px;
			font-family: $font-accent;
			font-size: 1.7rem;
			font-weight: 500;
		}

		.error-logo {
			width: 110px;
			margin-bottom: 25px;
		}

		.error-img {
			width: 302px;
			height: 136px;
			margin: 0 auto;

			&-404 {
				background-image: url("../../img/404.svg");
			}
		}

		.error-link {
			margin-top: 15px;
			font-size: 1.18rem;
		}
	}
}
