.services {
	&-item {
		position: relative;
		padding: 26px 33px;
		background-color: #fff;
		border-radius: 10px;
		box-shadow: 4px 4px 22px rgba(56, 123, 224, 0.15);
	}

	&-img {
		position: absolute;
		top: -30px;
		right: 20px;
		display: flex;
		width: 64px;
		height: 64px;
		justify-content: center;
		background-color: #fff;
		border-radius: 6px;
	}

	&-cont {
		font-family: $font-accent;
		font-weight: 600;
		line-height: 1.3;
	}

	&-name {
		margin-bottom: 20px;
		font-size: 1.2rem;
	}

	&-link {
		position: relative;
		display: inline-block;
		padding-right: 50px;
		font-size: 1.19rem;
		transition: all .25s ease;

		&::after {
			position: absolute;
			top: 6px;
			right: 0;
			width: 29px;
			height: 12px;
			background-image: url("../../img/icons/arrow-right.svg");
			content: "";
		}

		&:hover {
			padding-right: 60px;
		}
	}
}

.team {
	&-item {
		display: flex;
		margin-bottom: 30px;

		@include media-breakpoint-down(xs) {
			display: block;
			text-align: center;
		}
	}

	&-img > img {
		position: relative;
		width: 150px;
		height: 150px;
		margin-right: 25px;
		border-radius: 7px;

		@include media-breakpoint-down(xs) {
			width: 80px;
			height: 80px;
			margin-right: 0;
		}
	}

	&-name {
		margin-bottom: 5px;
		font-size: 1.3rem;
		font-weight: 500;
		line-height: 1.3;
	}

	&-position {
		font-size: 1.2rem;
	}

	&-descr {
		margin-top: 1rem;
		opacity: .7;
	}

	&-year {
		margin-top: 30px;
		text-align: center;

		h3 {
			font-family: $font-accent;
			font-size: 1.9rem;
			font-weight: 600;
		}
	}
}

.letters {
	&-item {
		padding-right: 10px;
	}

	&-img {
		width: 100%;
		height: 344px;
		margin-bottom: 15px;
		background-color: #fff;
		border-radius: 9px;
		box-shadow: 3px 4px 8px rgba(0, 0, 0, 0.08);
	}

	&-name {
		font-size: 1.15rem;
		line-height: 1.3;
	}
}
