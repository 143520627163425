.admin-plus {
	padding-top: 40px;
	padding-bottom: 45px;
	background-image: url("../../img/admin-pluse-bg.svg");
	background-size: cover;
	border-radius: 10px;
	color: #fff;

	.title-sect {
		padding: 0 20px;
		margin-bottom: 55px;
		color: #fff;
		font-size: 1.5rem;
	}

	&-item {
		text-align: center;

		@include media-breakpoint-down(md) {
			margin-bottom: 30px;
		}
	}

	&-img {
		width: 90px;
		margin-bottom: 30px;
	}

	&-name {
		padding: 0 10px;
		font-family: $font-accent;
		font-size: 1.15rem;
	}
}

.we-do {
	&-item {
		margin-bottom: 30px;
	}

	&-img {
		display: flex;
		width: 98px;
		height: 98px;
		align-items: center;
		margin: 0 auto;
		background: #fff;
		border-radius: 6px;
		box-shadow: 4px 4px 22px rgba(56, 123, 224, 0.15);

		img {
			margin: 0 auto;
		}
	}

	&-name {
		margin-top: 17px;
		font-size: 1.15rem;
		line-height: 1.4;
		text-align: center;
	}
}

.working-conditions {
	&-item {
		margin-bottom: 45px;
	}

	&-cont {
		&-title {
			margin-bottom: 15px;
			font-family: $font-accent;
			font-size: 1.2rem;
			font-weight: 600;
		}
	}
}

.case {
	.slick-slide {
		margin: 0 27px;
	}

	.slick-list {
		margin: 0 -27px;
	}

	&-item {
		overflow: hidden;
		padding: 40px 28px;
		border: 1px solid #daecff;
		background: #fff;
		border-radius: 5px;

		&-top {
			display: flex;
			padding-bottom: 30px;
			border-bottom: 1px solid rgba(0, 0, 0, .1);
		}

		&-logo {
			width: 130px;
			background: #ccc;
		}

		&-title {
			margin-left: 20px;
		}

		&-name {
			color: #333;
			line-height: 1.4;
		}

		&-cont {
			padding-top: 30px;
		}

		&-text {
			margin-bottom: 20px;

			span {
				font-weight: 500;
			}
		}
	}
}

.slick-nav-arrow {
	position: absolute;
	top: 50%;
	display: flex;
	width: 38px;
	height: 38px;
	align-items: center;
	border: 1px solid #bfd5f6;
	background-color: #fff;
	border-radius: 50%;
	cursor: pointer;
	transition: all .25s ease;

	&:hover {
		border-color: #89a8d6;

		svg {
			path {
				fill: $accent-color-hover;
			}
		}
	}

	&-prev {
		left: -45px;

		svg {

			margin-left: 12px;
		}
	}

	&-next {
		right: -45px;

		svg {
			margin-left: 15px;
			transform: rotate(180deg);
		}
	}
}

.article-admin {
	background-image: url("../../img/bg-elem/elem-white-right.svg");
	background-position: top right;
	background-repeat: no-repeat;
}

.admin-skills-sect {
	background-image: url("../../img/bg-elem/elem-left.svg");
	background-position: top left;
	background-repeat: no-repeat;
	background-size: 150px;
}

.admin-conds-sect {
	background-image: url("../../img/bg-elem/elem-left.svg");
	background-position: top left;
	background-repeat: no-repeat;
	background-size: 150px;
}
