.contacts-sect {
	margin-top: 65px;
}

.contacts {
	&-phones {
		display: flex;

		@include media-breakpoint-down(sm) {
			display: block;
		}
	}

	&-phone {
		margin-right: 65px;

		@include media-breakpoint-down(sm) {
			margin-right: 0;
			margin-bottom: 30px;
		}

		&:last-child {
			margin-right: 0;
		}

		&__name {
			font-size: 1.25rem;
		}

		&__link {
			position: relative;
			padding-left: 34px;
			color: $black-color;
			font-family: $font-accent;
			font-size: 2rem;
			font-weight: 500;

			@include media-breakpoint-down(xs) {
				font-size: 1.8rem;

				&::before {
					top: 5px;
				}
			}

			&::before {
				position: absolute;
				top: 8px;
				width: 24px;
				height: 24px;
				margin-left: -34px;
				background-image: url("../../img/icons/phone-contact.svg");
				content: "";
			}

			&:hover {
				color: $black-color;
			}
		}
	}

	&-other {
		display: flex;
		margin-top: 60px;

		@include media-breakpoint-down(md) {
			display: block;
		}

		&__item {
			position: relative;
			width: 33.3%;

			@include media-breakpoint-down(md) {
				width: 100%;
				margin-bottom: 30px;
			}

			&::before {
				position: absolute;
				left: -50px;
				width: 120px;
				height: 120px;
				background-image: url("../../img/visual-elem-01.svg");
				background-position: -10px center;
				background-repeat: no-repeat;
				content: "";

				@include media-breakpoint-down(sm) {
					left: 0;
				}
			}
		}
	}

	&-ask {
		margin-top: 60px;

		&__name {
			font-size: 21px;
		}

		&__text {
			font-family: $font-accent;
			font-size: 1.7rem;
			font-weight: 500;
		}

		&__message {
			border-bottom: 1px dashed $accent-color;
		}

		&__mail {
			color: $black-color;

			&:hover {
				color: $black-color;
			}
		}
	}

	&-social {
		display: flex;
		margin-top: 60px;

		&__block {
			margin-right: 60px;

			&:last-child {
				margin-right: 0;
			}
		}

		&__name {
			margin-bottom: 10px;
			font-size: 1.4rem;
		}

		&__link {
			margin-right: 10px;

			&:last-child {
				margin-right: 0;
			}
		}
	}
}

.contact-other {
	&__name {
		font-size: 1.3rem;
	}

	&__link {
		position: relative;
		padding-left: 34px;
		color: $black-color;
		font-family: $font-accent;
		font-size: 1.35rem;
		font-weight: 500;

		@include media-breakpoint-down(xs) {
			font-size: 1.2rem;

			&::before {
				top: 2px;
			}
		}

		&::before {
			position: absolute;
			top: 2px;
			width: 24px;
			height: 24px;
			margin-left: -34px;
			background-image: url("../../img/icons/mail-contact.svg");
			content: "";
		}

		&:hover {
			color: $black-color;
		}
	}

	&__time {
		color: #696969;
		font-size: 1.18rem;
	}
}

.address {
	&__map {
		width: 100%;

		iframe {
			border-radius: 9px;
		}
	}

	&__content {
		margin-top: 10px;
		margin-bottom: 5px;
		font-size: 1.3rem;
		line-height: 1.5;
	}

	&__time {
		color: #696969;
		font-size: 1rem;

		@include media-breakpoint-down(md) {
			margin-bottom: 30px;
		}
	}
}

.contacts-requisites {
	&:first-child {
		@include media-breakpoint-down(md) {
			margin-bottom: 40px;
		}
	}

	&__title {
		margin-bottom: 30px;
	}

	&__pdf {
		border-bottom: 1px dashed $accent-color;
	}

	&__contur {
		&-name {
			font-size: 1.15rem;
		}

		&-logo {
			width: 157px;
		}
	}
}

.contact-info-title {
	font-family: $font-accent;
	font-size: 1.35rem;
	font-weight: 600;
}

.contact-info-row {
	display: flex;
	margin-bottom: 20px;
	font-size: 1.18rem;

	@include media-breakpoint-down(xs) {
		display: block;
	}

	&:last-child {
		margin-bottom: 30px;
	}

	&__name {
		width: 35%;
		line-height: 1.5;
		opacity: .4;

		@include media-breakpoint-down(xs) {
			width: 100%;
		}
	}

	&__data {
		width: 65%;

		@include media-breakpoint-down(xs) {
			width: 100%;
		}
	}
}

.contacts-docs {
	&__title {
		margin-top: 30px;
		margin-bottom: 15px;
	}

	&__wrap {
		display: flex;

		@include media-breakpoint-down(md) {
			display: block;
		}
	}

	&__item {
		position: relative;
		margin-right: 45px;
		margin-left: 35px;
		color: $base-text-color;
		font-size: 1.2rem;

		@include media-breakpoint-down(md) {
			display: block;
			margin-bottom: 20px;
		}

		&:last-child {
			margin-bottom: 0;
		}

		&:hover {
			color: $black-color;
		}

		&:last-child {
			margin-right: 0;
		}

		&::before {
			position: absolute;
			top: 4px;
			width: 24px;
			height: 24px;
			margin-left: -35px;
			background-image: url("../../img/icons/save.svg");
			content: "";
		}
	}
}
