.footer {
	padding: 42px 0;
	margin-top: 80px;
	background: linear-gradient(180deg, #273b44 0%, #132a34 100%);
	color: #fff;
}

.foot-logo {
	@include media-breakpoint-down(sm) {
		text-align: center;
	}
	@include media-breakpoint-down(md) {
		margin-bottom: 30px;
	}
	@include media-breakpoint-down(lg) {
		svg {
			width: 100%;
		}
	}

	&__descr {
		margin-top: 5px;
		opacity: .8;
	}
}

.foot-social {
	margin-top: 10px;

	@include media-breakpoint-down(md) {
		margin-bottom: 30px;
		text-align: center;
	}

	&-link {
		margin-top: 6px;

		a {
			display: inline-block;
			margin-right: 12px;
			margin-bottom: 10px;

			&:last-child {
				margin-right: 0;
			}
		}
	}
}

.foot-nav {
	display: flex;
	justify-content: space-between;

	@include media-breakpoint-down(sm) {
		flex-wrap: wrap;
	}
	@include media-breakpoint-down(xs) {
		text-align: center;
	}
	//@include media-breakpoint-down(320px) {
	//	display: block;
	//}
}

.foot-nav-item {
	@include media-breakpoint-down(sm) {
		width: 50%;
	}
	@include media-breakpoint-down(xs) {
		margin-bottom: 30px;
	}
	//@include media-breakpoint-down(320px) {
	//	width: 100%!important;
	//}

	&__title {
		margin-bottom: 10px;
		opacity: .4;
		text-transform: uppercase;
	}

	&__list {
		padding: 0;
	}

	&__item {
		margin-bottom: 5px;
		list-style-type: none;
	}

	&__link {
		color: #fff;
		opacity: .8;
		transition: opacity .25s ease;

		&:hover {
			color: #fff;
			opacity: 1;
		}
	}
}

.foot-line {
	margin: 20px 0;
	opacity: .3;
}

.foot-center {
	display: flex;
	justify-content: space-between;

	@include media-breakpoint-down(md) {
		display: block;
	}
}

.foot-subscribe {
	&__name {
		margin-bottom: 5px;
		font-family: $font-accent;
		font-size: 1.1rem;
		font-weight: 500;
	}

	.form__input {
		width: 290px;
		padding: 17px 18px;
		margin-right: 10px;

		@include media-breakpoint-down(xs) {
			width: 100%;
		}
	}

	.form__label {
		@include media-breakpoint-down(xs) {
			display: block;
		}
	}

	.button {
		position: relative;
		top: -1px;

		@include media-breakpoint-down(xs) {
			width: 100%;
		}
	}
}

.foot-contacts {
	display: flex;
	justify-content: flex-end;
	margin-top: 37px;

	@include media-breakpoint-down(lg) {
		margin-top: 30px;
	}
	@include media-breakpoint-down(md) {
		justify-content: flex-start;
	}
	@include media-breakpoint-down(sm) {
		flex-direction: column;
		justify-content: center;
	}

	&__item {
		margin-right: 45px;

		@include media-breakpoint-down(sm) {
			margin-right: 0;
			margin-bottom: 20px;
		}

		&:last-child {
			margin-right: 0;
		}
	}
}

.foot-contact {
	text-align: right;

	@include media-breakpoint-down(md) {
		text-align: left;
	}
	@include media-breakpoint-down(sm) {
		text-align: center !important;
	}

	&__title {
		opacity: .6;
	}

	&__icon {
		position: relative;
		top: -3px;
		margin-right: 10px;
	}

	&__link {
		color: #fff;
		font-family: $font-accent;
		font-size: 1.3rem;
		font-weight: 400;

		&:hover {
			color: #fff;
		}
	}
}

.foot-bottom {
	display: flex;
	align-items: flex-end;
	justify-content: space-between;
	margin-top: 30px;

	@include media-breakpoint-down(sm) {
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}
	@include media-breakpoint-down(md) {
		display: block;
	}

	&__nav {
		@include media-breakpoint-down(sm) {
			text-align: center;
		}

		> a {
			margin-right: 30px;
			color: #fff;
			opacity: .7;

			&:last-child {
				margin-right: 0;
			}

			&:hover {
				opacity: 1;
			}
		}
	}
}

.foot-reserved,
.foot-requisites {
	font-size: 0.95rem;
	opacity: .6;

	@include media-breakpoint-down(sm) {
		text-align: center;
	}
	@include media-breakpoint-down(md) {
		margin-bottom: 10px;
	}

	a {
		color: inherit;
	}
}
