.vds-info {
	display: grid;
	width: 320px;
	margin-top: 30px;
	grid-gap: 30px;

	@include media-breakpoint-down(md) {
		width: 100%;
	}

	&-item {
		width: 100%;
		padding: 9px 20px;
		background: linear-gradient(180deg, #273b44 0%, #132a34 100%);
		border-radius: 8px;
		color: #fff;

		&:first-child {
			grid-column-end: 3;
			grid-column-start: 1;
		}

		&-name {
			position: relative;
			padding-left: 30px;
			margin-bottom: 5px;
			opacity: .7;

			&::before {
				position: absolute;
				top: 4px;
				width: 20px;
				height: 20px;
				margin-left: -30px;
				content: "";
			}

			&-cpu::before {
				background-image: url("../../img/icons/cpu.svg");
			}

			&-ram::before {
				background-image: url("../../img/icons/server.svg");
			}

			&-disk::before {
				background-image: url("../../img/icons/hard-drive.svg");
			}
		}

		&-data {
			font-family: $font-accent;
			font-size: 23px;
			font-weight: 600;
			line-height: 1.5;
		}
	}
}

.terminal {
	padding: 10px 33px;
	background-color: #282a36;
	border-radius: 6px;
	color: #acacac;

	@include media-breakpoint-down(md) {
		margin-bottom: 30px;
	}

	&-window {
		position: relative;
		margin-bottom: 10px;
		color: #666870;
		font-family: $font-code;
		font-size: 13px;
		text-align: center;

		&-circle {
			position: absolute;
			top: 3px;

			span {
				display: inline-block;
				width: 13px;
				height: 13px;
				margin-right: 2px;
				background-color: #fff;
				border-radius: 50%;

				&:first-child {
					background-color: #ff6159;
				}

				&:nth-child(2) {
					background-color: #ffc231;
				}

				&:last-child {
					background-color: #18cf42;
				}
			}
		}
	}

	&-content {
		font-family: $font-code;
		font-size: 13px;
	}
}

.vds-content {
	&-title {
		margin-bottom: 20px;
		font-family: $font-accent;
		font-size: 22px;
		font-weight: 600;
	}
}

.vds-options {
	ul {
		padding: 0;
	}

	li {
		position: relative;
		padding-left: 30px;
		margin-bottom: 10px;
		list-style-type: none;

		&::before {
			position: absolute;
			top: 7px;
			left: 0;
			width: 18px;
			height: 13px;
			background-image: url("../../img/icons/good.svg");
			background-size: cover;
			content: "";
		}
	}
}

.vds-kvm {
	padding: 16px 33px;
	margin-top: 40px;
	background: #fff;
	border-radius: 10px;

	@include media-breakpoint-down(md) {
		margin-bottom: 30px;
	}

	&-name {
		color: #2f5175;
		font-size: 64px;
		font-weight: bold;
		line-height: 75px;
		opacity: 0.1;
	}

	&-title {
		margin-top: 20px;
		margin-bottom: 5px;
		font-size: 20px;
		font-weight: 500;
	}

	&-text {
		padding-bottom: 10px;
	}
}

.vds-more-options {
	&-item {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 10px;

		&::before {
			position: absolute;
			z-index: 1;
			bottom: 6px;
			width: 100%;
			height: 2px;
			background-image: url("../../img/icons/dot.svg");
			background-repeat: repeat-x;
			content: "";
		}

		> div {
			z-index: 2;
			background-color: $body-bg;
		}
	}

	&-list {
		margin-top: 45px;
	}

	&-name {
		padding-right: 10px;
	}

	&-data {
		padding-left: 10px;
	}
}

.all-inclusive {
	&-item {
		display: flex;
		align-items: center;
		margin-bottom: 30px;

		@include media-breakpoint-down(sm) {
			display: block;
			text-align: center;
		}
	}

	&-icon {
		display: flex;
		width: 98px;
		height: 98px;
		align-items: center;
		justify-content: center;
		background-color: #fff;
		border-radius: 6px;
		box-shadow: 4px 4px 22px rgba(56, 123, 224, 0.15);

		@include media-breakpoint-down(sm) {
			margin: 0 auto;
		}
	}

	&-text {
		max-width: 200px;
		margin-left: 25px;
		font-family: $font-accent;
		font-size: 18px;
		font-weight: 500;

		@include media-breakpoint-down(lg) {
			max-width: 150px;
			padding-right: 20px;
		}
		@include media-breakpoint-down(md) {
			max-width: 200px !important;
			padding-right: 0 !important;
		}
		@include media-breakpoint-down(sm) {
			padding-top: 10px;
			margin: 0 auto;
		}
	}
}

.plus-vds {
	&-list {
		display: flex;
		flex-wrap: wrap;
		align-items: flex-start;
		justify-content: center;
		padding: 0;

		@include media-breakpoint-down(sm) {
			display: block;
		}
	}

	&-item {
		position: relative;
		flex: 1 1 30%;
		padding-left: 30px;
		margin-bottom: 20px;
		font-size: 18px;
		list-style-type: none;

		@include media-breakpoint-down(md) {
			flex: 1 1 50%;
		}

		&::before {
			position: absolute;
			top: 7px;
			left: 0;
			width: 18px;
			height: 13px;
			background-image: url("../../img/icons/good-vds.svg");
			background-size: cover;
			content: "";
		}
	}
}
