.modal {
	position: fixed;
	z-index: 2;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	display: flex;
	width: 100%;
	height: 100%;
	align-items: center;
	backdrop-filter: blur(5px);
	background: rgba(0, 0, 0, .5);
	overflow-x: hidden;
	overflow-y: scroll;
	transition: opacity .5s ease-out;

	&__wrap {
		position: relative;
		max-width: 530px;
		padding: 32px 40px;
		margin: 40px auto;
		background: #fff;

		@media screen and (max-height: 600px) {
			height: 500px;
			padding: 10px 20px;
		}

		@include media-breakpoint-down(xs) {
			max-width: 310px;
			padding: 15px 30px;
		}
		@include media-breakpoint-down(320px) {
			overflow: auto;
		}
	}

	&__close {
		position: absolute;
		top: 7px;
		right: 7px;
		width: 34px;
		height: 34px;
		background-image: url("../../img/icons/close.svg");
		cursor: pointer;
	}

	&__title {
		padding: 0 25px;
		margin-top: 9px;
		font-family: $font-accent;
		font-size: 1.2rem;
		font-weight: 600;
		line-height: 1.5;
		text-align: center;

		@include media-breakpoint-down(xs) {
			padding: 0;
			line-height: 1.3;
			text-align: center;
		}

		&-desc {
			display: block;
			margin-top: 6px;
			font-family: $font-default;
			font-size: 1.15rem;
			font-weight: normal;
			text-align: center;

			@include media-breakpoint-up(xs) {
				font-size: 0.95rem;
			}
		}
	}

	&__error {
		margin-top: 10px;
		color: $error-color;
		text-align: center;
	}

	&__form {
		padding: 0 25px;
		margin-top: 20px;

		@media screen and (max-height: 600px) {
			margin-top: 10px;
		}

		@include media-breakpoint-down(xs) {
			max-width: 320px;
			padding: 0;
			margin-top: 10px;
		}

		.disclaimer {
			display: block;
			margin-top: 20px;
			font-size: 0.9rem;
			line-height: 1.5;
			opacity: .8;

			@include media-breakpoint-up(xs) {
				margin-top: 10px;
				line-height: 1.3;
			}
		}

		.label {
			position: relative;
			width: 100%;
			margin-top: 14px;

			@include media-breakpoint-down(xs) {
				margin-top: 10px;
			}

			&:last-child {
				margin-bottom: 27px;
			}

			&::before {
				position: absolute;
				z-index: 100;
				top: 5px;
				display: block;
				width: 48px;
				height: 48px;
				content: "";

				@include media-breakpoint-down(xs) {
					top: -2px;
				}
			}
		}

		.name {
			&::before {
				background-image: url("../../img/icons/input/name.svg");
			}
		}

		.email {
			&::before {
				background-image: url("../../img/icons/input/email.svg");
			}
		}

		.pass {
			&::before {
				background-image: url("../../img/icons/input/pass.svg");
			}
		}

		.phone {
			&::before {
				background-image: url("../../img/icons/input/phone.svg");
			}
		}

		.text {
			&::before {
				background-image: url("../../img/icons/input/text.svg");
			}
		}

		.map {
			&::before {
				background-image: url("../../img/icons/input/map.svg");
			}
		}

		.forget-password {
			text-align: right;
		}

		.link-bottom {
			margin-top: 15px;
			font-size: 17px;
			text-align: center;
		}

		.form__select {
			position: relative;
			display: inline-block;
			width: 100%;
			padding: 18px 43px;
			border: 1px solid $accent-light-color;
			appearance: none;
			background-image: url("../../img/icons/select-arrow.svg");
			background-position: 95% 50%;
			background-repeat: no-repeat;
			border-radius: 10px;
			color: #7d7d7d;
			line-height: 1.3rem;
			outline: none;

			@include media-breakpoint-down(xs) {
				padding-top: 10px;
				padding-bottom: 10px;
				padding-left: 45px;
			}
		}

		.form__input {
			position: relative;
			display: inline-block;
			width: 100%;
			padding-left: 45px;

			@include media-breakpoint-down(xs) {
				padding-top: 10px;
				padding-bottom: 10px;
				padding-left: 45px;
			}

			&::placeholder {
				color: #7d7d7d;
			}

			&.error {
				border-color: #f1492c;
			}

			@media screen and (max-height: 600px) {
				padding: 10px;
			}
		}

		.input__error {
			color: #f1492c;
			font-size: 0.8rem;
		}

		.form__button {
			width: 100%;
			padding: 18px 0;
			margin-top: 21px;
			font-size: 18px;
		}

		.form__check {
			display: block;
			margin-top: 22px;

			.check__box {
				margin-top: 2px;
			}
		}
	}
}

.zoom-anim-dialog {
	animation-duration: 0.6s;
	animation-name: zoom-in;
}
