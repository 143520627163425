.top-banner {
	padding-top: 40px;

	&-content {
		margin: 20px 0;

		@include media-breakpoint-down(md) {
			text-align: center;
		}
	}

	&-image {
		text-align: center;

		@include media-breakpoint-down(md) {
			margin-top: 20px;

			img {
				width: 100%;
			}
		}
	}

	&-btn {
		margin-top: 50px;
	}

	&-price {
		font-size: 27px;
	}
}
