.hyper-logo {
	.shape {
		transition: all .15s ease;

		&:hover {
			fill: #c5e9ff;
			transition: all 0.3s ease-out;
		}
	}
}
