// Widgets
.widget-support {
	position: fixed;
	z-index: 100;
	right: 40px;
	bottom: 40px;
	display: none;

	@include media-breakpoint-down(xs) {
		right: 0;
		bottom: 10px;
	}

	.item {
		position: relative;
		display: inline-block;
		overflow: hidden;
		width: 48px;
		height: 48px;
		padding-left: 28px;
		margin-right: 16px;
		background-color: $accent-color;
		background-position: 210px 12px;
		background-repeat: no-repeat;
		border-radius: 24px;
		box-shadow: 0 2px 8px 0 rgba(0, 0, 0, .24);
		cursor: pointer;
		line-height: 48px;
		transition: width .5s;
		vertical-align: top;

		&::before {
			position: absolute;
			top: 0;
			right: 11px;
			bottom: 0;
			width: 24px;
			height: 24px;
			margin: auto 0;
			background-image: url("../../img/icons/chat.svg");
			content: "";
		}

		&:hover {
			overflow: visible;
			width: 230px;

			.text {
				opacity: 1;
				transition: opacity .5s ease-in-out .1s;
			}
		}
	}

	.text {
		display: inline-block;
		overflow: hidden;
		width: 200px;
		color: #fff;
		opacity: 0;
	}
}

// Hide Chat copyright line
.online-chat-root {
	.chat-history-wrapper {
		.bottom-area {
			.copyright {
				display: none !important;
			}
		}
	}
}
